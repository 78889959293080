<template>
  <div v-if="termsMissing">
    <v-alert type="error">
      Please agree to the Quest terms and conditions before continuing. If you do not accept the terms, you may delete your account.
    </v-alert>
    <Loading
      :loading="loading"
      message="Loading Terms"
    />
    <v-card v-if="terms">
      <v-card-title>
        {{ terms.name }}
      </v-card-title>
      <v-card-text>
        <!-- eslint-disable vue/no-v-html -->
        <div
          style="max-height:398px; overflow:auto"
          class="page-content"
          v-html="terms.content"
        />
        <!--eslint-enable-->
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="terms"
          color="primary"
          @click="agree"
        >
          I Agree
        </v-btn>
        <v-btn
          v-if="terms"
          :to="{ name: 'ProfileDelete' }"
        >
          Delete My Account
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { allianceAPI } from '@/api'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    Loading,
  },
  data: function() {
    return {
      terms: null,
      loading: false,
      submitting: false,
    }
  },
  computed: {
    ...mapGetters({
      termsMissing: 'auth/termsMissing',
    }),
  },
  watch: {
    termsMissing: function () {
      this.redirect()
    },
  },
  created: function() {
    this.next = '/'
    if (this.$route.params && this.$route.params.next) {
      this.next = this.$route.params.next
    }
    this.$store.dispatch('auth/getProfile').finally(() => this.redirect())
    this.getTerms()
  },
  methods: {
    redirect() {
      // If the terms are not missing, send the user home.
      if (!this.$store.getters['auth/termsMissing']) {
        return this.$router.replace(this.next)
      }
    },
    getTerms() {
      this.loading = true
      return this.$store.dispatch('pages/getDetails', '/alliance/page/detail/?path=/terms/')
        .finally(() => {
          this.terms = this.$store.getters['pages/getDetailsByAttribute']({key: 'path', value: '/terms/'}) || null
          this.loading = false
        })
    },
    agree() {
      this.submitting = true
      const data = new FormData()
      data.append('has_accepted_terms', true)
      return allianceAPI.put(
        '/alliance/profile/me/',
        data,
      )
        .then(() => {
          this.$store.dispatch('auth/getProfile')
        })
        .finally(() => {
          this.submitting = false
          this.$store.dispatch('auth/termsAgree')
        })
    },
  },
}
</script>
